<template>
    <div class="my-order-list">
        <a-row class="content" v-loading="loading" :gutter="24" style="margin-top: 20px; width: 100%; margin-left: 0"
            element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)">
            <div style="width: 100%; display: flex; justify-content: center">
                <template v-if="MyRecordsList.length > 0 ? false : true">
                    <a-row type="flex" justify="center"
                        style="margin-bottom: 10px; margin-top: 10px; min-height: 400px">
                        <el-empty :description="description"></el-empty>
                    </a-row>
                </template>
            </div>
            <div class="goods-card-box">
                <el-card class="box-card" v-for="(item, index) in MyRecordsList" :key="index">
                    <el-form style="margin-bottom:30px" ref="form" label-width="100px" label-position="left">
                        <el-form-item label="订单号">
                            <span style="word-break: break-all">{{ item.orderNumber }}</span>
                        </el-form-item>
                        <el-form-item label="商品名称">
                            <div class="goods-name">
                                <span>{{ item.integralGiftWait.giftName }}</span>
                            </div>
                        </el-form-item>
                        <el-form-item label="商品封面">
                            <div style="text-align:left">
                                <img style="width:100%;"
                                    :src="item.integralGiftWait.multiMediaList && item.integralGiftWait.multiMediaList[0].cover"
                                    alt="商品封面" />
                            </div>
                        </el-form-item>
                        <el-form-item label="商品数量">
                            <span>{{ item.giftCount }}</span>
                        </el-form-item>
                        <el-form-item label="消耗积分">
                            <span>{{ item.integralGiftWait.needIntegral }}</span>
                        </el-form-item>
                        <el-form-item label="订单状态">
                            <span v-if="item.status === 0">待发货</span>
                            <span v-if="item.status === 1">已发货</span>
                        </el-form-item>
                    </el-form>
                    <el-button class="detailsBtn" type="primary" @click="seeDetails(item)">查看详情</el-button>
                </el-card>
            </div>
        </a-row>

        <!-- 分页器 -->
        <a-row type="flex" justify="center" style="margin-bottom: 10px; margin-top: 10px">
            <el-pagination background @current-change="handleCurrentChange" :current-page="pageIndex"
                :page-size="pageSize" layout="total, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
        </a-row>

        <!-- 查看详情弹框 -->
        <el-dialog title="订单详情" :visible.sync="dialogVisible" width="30%">
            <el-form ref="recordsDetailsObj" label-width="110px" label-position="left">
                <el-form-item label="商品名称">
                    <span>{{ giftName }}</span>
                </el-form-item>
                <el-form-item label="兑换人姓名">
                    <span>{{ recordsDetailsObj.receiverUser }}</span>
                </el-form-item>
                <el-form-item label="兑换人电话号码">
                    <span>{{ recordsDetailsObj.receiverMobile }}</span>
                </el-form-item>
                <el-form-item label="兑换人地址">
                    <span>{{ recordsDetailsObj.receiverAddress }}</span>
                </el-form-item>
                <el-form-item label="商品图片">
                    <div style="text-align:left" v-if="goodsImgs.length > 0">
                        <!-- <img style="width:60%;" :src="goodsImgs[0].content
                        " alt="商品封面" /> -->
                        <el-image style="width: 60%" :src="goodsImgs[0].content" :preview-src-list="srcList">
                        </el-image>
                    </div>
                </el-form-item>
                <el-form-item label="商品数量">
                    <span>{{ recordsDetailsObj.giftCount }}</span>
                </el-form-item>
                <el-form-item label="消耗积分">
                    <span>{{
                        integral
                    }}</span>
                </el-form-item>
                <el-form-item label="订单状态">
                    <span v-if="itemStatus === 0">待发货</span>
                    <span v-if="itemStatus === 1">已发货</span>
                </el-form-item>
                <el-form-item label="下单时间">
                    <span>{{ recordsDetailsObj.createDate }}</span>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pageIndex: 1,
            pageSize: 15,
            MyRecordsList: [],
            empty: true,
            recordsDetailsObj: {},
            integral: '',
            itemStatus: -1,
            goodsImgs: [],
            giftName: "",
            loading: false,
            srcList: [],
            description: "暂无数据",
            dialogVisible: false,
            id: "",
            index: -1,
            totalCount: 0,
        };
    },
    created() {
        this.getMyRecordsList();
    },
    methods: {
        // 获取我的兑换记录列表
        getMyRecordsList() {
            this.loading = true;
            this.$HTTP
                .httpToken({
                    url: "/wst/integral/my/redeem",
                    method: "post",
                    data: {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    },
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.loading = false;
                        this.totalCount = res.data.count;
                        this.MyRecordsList = [...res.data.list];
                        console.log("111", res);
                    }
                })
                .catch((error) => {
                    //   loading.close();
                    this.loading = false;
                    this.$elementMessage.error("获取兑换记录失败~");
                });
        },
        // 点击查看详情
        seeDetails(item) {
            this.dialogVisible = true;
            this.recordsDetailsObj = { ...item };
            this.integral = item.integralGiftWait.needIntegral;
            this.goodsImgs = item.integralGiftWait.multiMediaList;
            this.itemStatus = item.status;
            this.srcList = [this.goodsImgs[0].content];
            this.giftName = item.integralGiftWait.giftName;
            console.log('111 :>> ', this.recordsDetailsObj);
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getMyRecordsList();
        },
    },
};
</script>

<style lang="scss" scoped>
.text {
    font-size: 14px;
}

.item {
    margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}

// .box-card {
//     width: 480px;
// }

// el-button颜色更改
.el-button--goon.is-active,
.el-button--goon:active {
    background: #37b6df;
    border-color: #37b6df;
    color: #fff;
}

.el-button--goon:focus,
.el-button--goon:hover {
    background: #37b9ff;
    border-color: #37b9ff;
    color: #fff;
}

.el-button--goon {
    color: #fff;
    background-color: #37b6df;
    border-color: #37b6df;
}

.el-form-item__content span {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.goods-card-box {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;

    // 最后一行是两个元素 
    .box-card:nth-child(3n+2) {
        margin-left: 2%;
        margin-right: 2%;
    }

    .box-card {
        position: relative;
        width: 32%;
        // margin-right: 2%;

        .detailsBtn {
            position: absolute;
            left: 50%;
            bottom: 2%;
            transform: translateX(-50%);
        }

        // &:nth-child(3n+2) {
        //     margin-right: 0;
        // }
    }
}

.content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .box-card {
        margin-bottom: 20px;
    }
}

.el-card {
    position: relative;

    .el-form {
        padding-bottom: 20px;
    }
}

.btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

::v-deep .el-form-item {
    margin-bottom: 0;
}

.message {
    width: 100px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content {
    margin: 0;
}
</style>